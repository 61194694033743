import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Grid } from "@evabot/eva-react-core-library/dist/atoms/Grid/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { AeSidebarTag } from "./AeSidebarTag";
import { useAppSelector } from "hooks";
import { checkActivityHistoryEnabled } from "utils";
import { useEffect } from "react";
import { getCookie } from "utils/cookie";
import { useDispatch } from "react-redux";
import { setLoggedInState, setMenuItems } from "store/globalStateReducer";


export interface AeSidebarSectionProps {
  activeTab:
  | "home"
  | "opportunities"
  | "stakeholders"
  | "emailTemplate"
  | "history"
  | "analytics"
  | "integration"
  | "stakeholdertag"
  | "prospectreport"
  | "teammanagement"
  | "nudges"
  | "memberlisting"
  | "frameworkandcontext"
  | "prospectprocess"
  | "companyscorereport"
  | "emailtemplates"
  | "automatedemail"
  | "accountreport"
  | "adminreport"
  | "apollosearch"
  | "otherintegration"
  | "managepersonas"
  | "manageUseCase"
  | "faq"

}



export const AeSidebarSection: React.FC<AeSidebarSectionProps> = ({
  activeTab,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const loggedInMember = useAppSelector(
    (state) => state.profileState.profileData
  );

  const menuItems = useAppSelector((state) => state.globalState.menuItems);
  const accountCount = useAppSelector((state) => state.globalState.accountCount);
  const prospectCount = useAppSelector((state) => state.globalState.prospectCount);
  const menuItemFiltered = menuItems.filter(menu => !!menu);
  const count = { "companyscorereport": accountCount, "prospectreport": prospectCount }


  function clearAuthenticationCookies() {
    const cookieNames = ["user", "evaAuthToken", "token"];
    cookieNames.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  }

  const handleMenuClick = (menuItem) => {
    if (menuItem.name === "faq") {
      navigate("/faq")
      return;
    }
    if (menuItem.dbValue === 'history' && !checkActivityHistoryEnabled(loggedInMember)) {
      return;
    }
    navigate(menuItem.route);
  };

  useEffect(() => {
    const checkCookie = getCookie("evaAuthToken");
    if (!checkCookie) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("organizationId");
      clearAuthenticationCookies();
      dispatch(setMenuItems([]));
      dispatch(setLoggedInState(false));
      if (location.pathname !== '/faq') {
        navigate("/aeLogin1");
      }
    }
  }, []);

  return (
    <Flex
      flexDirection={"column"}
      m="50px 24px 24px 30px"
      color="white"
      justifyContent={"space-between"}
      height="100%"
      overflow={"scroll"}
      style={{ scrollbarWidth: "none" }}
    >
      <Grid gridRowGap={"20px"}>
        {menuItemFiltered.map((menu) => (
          // companyscorereport, prospectreportt
          <AeSidebarTag
            active={activeTab === menu.activeTab}
            text={count[menu.dbValue] ? menu.name + " (" + count[menu.dbValue] + ")" : menu.name}
            iconName={menu.iconName}
            onTagClick={() => handleMenuClick(menu)}
          />

        ))}
        <AeSidebarTag
          active={activeTab === "faq"}
          text="FAQs"
          iconName="faqIcon"
          onTagClick={() => handleMenuClick({ name: "faq" })}
        />
      </Grid>
    </Flex>

  );
};
