import { useEffect, useState } from "react";
import { Icon as EvaLibraryIcon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import { TextField, Chip, Box, Snackbar, Alert, Dialog, DialogActions, Tooltip, DialogContent, DialogTitle, Backdrop } from "@mui/material";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { axiosHelperCore } from "utils";
import { useAppSelector } from "hooks";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import DeleteButton from "containers/FrameworkAndContext/component/DeleteButton";
import EditButton from "containers/FrameworkAndContext/component/EditButton";
import NoRecordFound from "containers/ManagePersonas/component/NoRecordFound";
import DeleteConfirmationPopup from "containers/ManagePersonas/component/DeleteConfirmationPopup";
import ManageUseCaseHeader from "./component/ManageUseCaseHeader";
import Select from 'react-select';
import userService from "services/userService";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";

function ManageUseCaseContent() {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    type Severity = 'info' | 'error' | 'warning' | 'success';

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<Severity>('info');

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessageOld, setSnackbarMessageOld] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    const [useCaseName, setUseCaseName] = useState('');
    const [useCaseNameError, setUseCaseNameError] = useState(false);
    const [useCases, setUseCases] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [selectedUseCase, setSelectedUseCase] = useState(null);
    const [persona, setPersona] = useState('');
    const [pitch, setPitch] = useState('');
    const [crmMappingField, setCrmMappingField] = useState('');
    const [trackingInput, setTrackingInput] = useState('');
    const [trackingKeywords, setTrackingKeywords] = useState([]);
    const [trackingLimit, setTrackingLimit] = useState(10);
    const [factInput, setFactInput] = useState('');
    const [factSignalKeywords, setFactSignalKeywords] = useState([]);
    const [factLimit, setFactLimit] = useState(10);
    const [hiringInput, setHiringInput] = useState('');
    const [hiringSignalKeywords, setHiringSignalKeywords] = useState([]);
    const [hiringLimit, setHiringLimit] = useState(10);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [activated, setActivated] = useState(true);
    const [isTrackingKeywordsChanged, setIsTrackingKeywordsChanged] = useState(false);
    const [isFactSignalKeywordsChanged, setIsFactSignalKeywordsChanged] = useState(false);
    const [isHiringSignalKeywordsChanged, setIsHiringSignalKeywordsChanged] = useState(false);
    const [pitchError, setPitchError] = useState(false);
    const [trackingError, setTrackingError] = useState(false);
    const [factError, setFactError] = useState(false);
    const [hiringError, setHiringError] = useState(false);
    const [crmMappingFieldError, setCrmMappingFieldError] = useState(false);
    const [memberData, setMemberData] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userListFieldError, setUserListFieldError] = useState(false);
    const [customInsights, setCustomInsights] = useState([]);

    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);

    const showSnackbar = (message: string, severity: Severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const clearForm = () => {
        setPersona('');
        setPitch('');
        setCrmMappingField('');
        setTrackingInput("");
        setTrackingKeywords([]);
        setFactInput("");
        setFactSignalKeywords([]);
        setHiringInput("");
        setHiringSignalKeywords([]);
        setUseCaseName("");
        setUseCaseNameError(false);
        setPitchError(false);
        setCrmMappingFieldError(false);
        setUserListFieldError(false);
        setUserList([]);
    };

    const handleOpenPopup = (useCase) => {
        setIsPopupOpen(true);
        setSelectedUseCase(useCase);
    };

    const handleOpen = (useCase = null) => {
        if (useCase) {
            setEditMode(true);
            setSelectedUseCase(useCase);
            setUseCaseName(useCase?.useCaseName);
            setPersona(useCase?.persona);
            setPitch(useCase?.pitch);
            setCrmMappingField(useCase?.crmMappingField);
            setTrackingKeywords(useCase?.tracking?.keywords);
            setFactSignalKeywords(useCase?.fact?.keywords);
            setHiringSignalKeywords(useCase?.hiring?.keywords);
            setTrackingLimit(useCase?.tracking?.limit);
            setFactLimit(useCase?.fact?.limit);
            setHiringLimit(useCase?.hiring?.limit);
            setUserList(useCase?.userList || []);
        } else {
            setEditMode(false);
            clearForm();
        }
        setOpen(true);
    };

    const handleClose = (
        e?: React.MouseEvent<HTMLButtonElement>,
        reason?: "backdropClick" | "escapeKeyDown"
      ): void => {
        if (reason && ["backdropClick", "escapeKeyDown"].includes(reason)) return;
        clearForm();
        setOpen(false);
    };

    const handleSubmit = () => {
        const isUseCaseNameEmpty = !useCaseName.trim();
        const isPitchEmpty = !pitch.trim();
        const isCrmMappingFieldEmpty = !crmMappingField.trim();
        const isTrackingKeywordsEmpty = trackingKeywords?.length === 0;
        const isFactSignalKeywordsEmpty = factSignalKeywords?.length === 0;
        const isHiringSignalKeywordsEmpty = hiringSignalKeywords?.length === 0;
        const isUserListEmpty = userList?.length === 0;
    
        setUseCaseNameError(isUseCaseNameEmpty);
        setPitchError(isPitchEmpty);        
        setTrackingError(isTrackingKeywordsEmpty);
        setFactError(isFactSignalKeywordsEmpty);
        setHiringError(isHiringSignalKeywordsEmpty);
    
        let duplicateUseCase = null;
        let conflictingUser = null;

    
        if (customInsights.includes('useCase_2')) {
            setUserListFieldError(isUserListEmpty);
            if (isUserListEmpty) {
                showSnackbar("Please select at least one member.", "error");
                return;
            }

            userList.forEach(userId => {
                const conflictingUseCases = useCases.filter(useCase => 
                    useCase.userList.includes(userId) && 
                    (!editMode || useCase.useCaseId !== selectedUseCase?.useCaseId)
                );
                if (conflictingUseCases.length > 0) {
                    duplicateUseCase = conflictingUseCases.map(useCase => useCase.useCaseName).join(", ");
                    const user = memberData?.find(member => member.value === userId);
                    conflictingUser = user?.label;
                    showSnackbar(`${conflictingUser} cannot be added to this usecase as the user is already a part of ${duplicateUseCase}`, "error");
                    return;
                }
            });
    
            if(conflictingUser) return;
        } 

        if(customInsights.includes('useCase_1')){
            setCrmMappingFieldError(isCrmMappingFieldEmpty);
            if (isCrmMappingFieldEmpty) {
                showSnackbar("Please fill out all required fields.", "error");
                return;
            }

            const duplicateCrmFieldUseCase = useCases.find(p =>
                p.crmMappingField.trim().toLowerCase() === crmMappingField.trim().toLowerCase() &&
                (!editMode || p.useCaseId !== selectedUseCase?.useCaseId)
            );
    
            if (duplicateCrmFieldUseCase) {
                showSnackbar(`CRM mapping with the name "${crmMappingField}" already exists in use case "${duplicateCrmFieldUseCase.useCaseName}".`, "error");
                return;
            }
        }
    

        if (isUseCaseNameEmpty || isPitchEmpty) {
            showSnackbar("Please fill out all required fields.", "error");
            return;
        }
    
        const atLeastOneFilled = !(isTrackingKeywordsEmpty && isFactSignalKeywordsEmpty && isHiringSignalKeywordsEmpty);
        if (!atLeastOneFilled) {
            showSnackbar("At least one of 'Tracking', 'Hiring', or 'Fact' field must be filled.", "error");
            return;
        }
    
        const isDuplicateUseCaseName = useCases.some(p =>
            p.useCaseName.trim().toLowerCase() === useCaseName.trim().toLowerCase() &&
            (!editMode || p.useCaseId !== selectedUseCase?.useCaseId)
        );

        if (isDuplicateUseCaseName) {
            showSnackbar("A use case with the same name already exists.", "error");
            return;
        }

        
    
        const newUseCase = {
            useCaseName,
            persona,
            pitch,
            crmMappingField,
            trackingKeywords,
            factSignalKeywords,
            hiringSignalKeywords,
            activated,
            isTrackingKeywordsChanged,
            isFactSignalKeywordsChanged,
            isHiringSignalKeywordsChanged,
            userList
        };
    
        const obj = {
            ...newUseCase,
            ...(editMode ? { useCaseId: selectedUseCase?.useCaseId } : {}),
            userId: loggedInMemberDetails?.userId,
            organizationId: loggedInMemberDetails?.organizationId
        };
    
        addOrUpdateUseCase(obj);
    
        setOpen(false);
        clearForm();
    };
    

    const addOrUpdateUseCase = async (obj) => {
        setLoading(true);
        const personaList = await axiosHelperCore({
            url: `rapport/user/addOrUpdateUseCase`,
            method: "POST",
            JSONData: obj
        });

        if(personaList?.data?.result && personaList?.data?.status ){
            setUseCases(personaList?.data?.result);
            fetchUseCaseList(loggedInMemberDetails?.organizationId);
        }

        setLoading(false);

    };

    const handleToggle = async(useCaseId, value) => {
        setLoading(true);
        setActivated(value);

        const obj = { useCaseId : useCaseId, activated : value, userId : loggedInMemberDetails?.userId}

        const result = await axiosHelperCore({
            url: `rapport/user/toggleActiveUseCase`,
            method: "POST",
            JSONData: obj
        })

        fetchUseCaseList(loggedInMemberDetails?.organizationId);
        setLoading(false);
        
    };

    const handleTrackingKeywords = () => {

        if(trackingInput?.replace(/\s/g, "").length > 50){
            showSnackbar("Your tracking keyword is too long. Please enter a keyword with up to 50 characters.", "warning");
            return;
        }

        if(trackingKeywords?.length >= trackingLimit){
            showSnackbar(`Tracking keyword should not be more then ${trackingLimit} keywords`, "warning");
            return;
        }

        const trimmedTrackingInput = trackingInput?.trim()?.toLowerCase();

        if (trimmedTrackingInput && !trackingKeywords.map(t => t.toLowerCase()).includes(trimmedTrackingInput)) {
            setTrackingKeywords([...trackingKeywords, trackingInput.trim()]);
            setTrackingInput('');
            setIsTrackingKeywordsChanged(true);
        }else{
            showSnackbar("Same tracking keyword already exists.", "warning");
        }
    };

    const handleDeleteTrackingKeywords = (trackingToDelete) => {
        setTrackingKeywords(trackingKeywords.filter(tracking => tracking !== trackingToDelete));
        setIsTrackingKeywordsChanged(true);
    };

    const handleFactKeywords = () => {

        if(factInput?.replace(/\s/g, "").length > 50){
            showSnackbar("Your fact keyword is too long. Please enter a keyword with up to 50 characters.", "warning");
            return;
        }

        if(factSignalKeywords?.length >= factLimit){
            showSnackbar(`Tracking keyword should not be more then ${factLimit} keywords`, "warning");
            return;
        }

        const trimmedFactInput = factInput?.trim()?.toLowerCase();

        if (trimmedFactInput && !factSignalKeywords.map(t => t.toLowerCase()).includes(trimmedFactInput)) {
            setFactSignalKeywords([...factSignalKeywords, factInput?.trim()]);
            setFactInput('');
            setIsFactSignalKeywordsChanged(true);
        }else{
            showSnackbar("Same fact keyword already exists.", "warning");
        }
    };

    const handleDeleteFactKeywords = (factToDelete) => {
        setFactSignalKeywords(factSignalKeywords.filter(fact => fact !== factToDelete));
        setIsFactSignalKeywordsChanged(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleHiringKeywords = () => {

        if(hiringInput?.replace(/\s/g, "").length > 50){
            showSnackbar("Your hiring keyword is too long. Please enter a keyword with up to 50 characters.", "warning");
            return;
        }

        if(hiringSignalKeywords?.length >= hiringLimit){
            showSnackbar(`Hiring keyword should not be more then ${hiringLimit} keywords`, "warning");
            return;
        }

        const trimmedHiringInput = hiringInput?.trim()?.toLowerCase();

        if (trimmedHiringInput && !hiringSignalKeywords.map(t => t.toLowerCase()).includes(trimmedHiringInput)) {
            setHiringSignalKeywords([...hiringSignalKeywords, hiringInput?.trim()]);
            setHiringInput('');
            setIsHiringSignalKeywordsChanged(true);
        }else{
            showSnackbar("Same hiring keyword already exists.", "warning");
        }
    };

    const handleDeleteHiringKeywords = (hiringToDelete) => {
        setHiringSignalKeywords(hiringSignalKeywords.filter(hiring => hiring !== hiringToDelete));
        setIsHiringSignalKeywordsChanged(true);
    };

    const handleDeleteUseCase = async() => {
        setLoading(true);

        const obj = { useCaseId : selectedUseCase?.useCaseId, deleted : true, userId : loggedInMemberDetails?.userId}

        const result = await axiosHelperCore({
            url: `rapport/user/deleteUseCase`,
            method: "POST",
            JSONData: obj
        });

        if(result?.data?.result){
            setUseCases(useCases.filter((p) => p.useCaseId !== selectedUseCase?.useCaseId));
        }

        if(result?.data?.message){
            setSnackbarMessageOld(result?.data?.message);
            setShowSnackBar(true); 
            setSnackbarType('warning');
        }
        
        setLoading(false);
    };

    const fetchUseCaseList = async (organizationId = "") => {
        setLoading(true);
        const useCaseList = await axiosHelperCore({
            url: `rapport/user/fetchUseCaseList`,
            method: "POST",
            JSONData: { organizationId: organizationId }
        });

        if(useCaseList?.data?.result?.length > 0 && useCaseList?.data?.status ){
            setUseCases(useCaseList?.data?.result);
        }
        setLoading(false);
    }

    const handleSelectedMemberChange = (selectedOptions) => {
        setUserList(selectedOptions.map(option => option.value));
    };

    const getMemberList = async (organizationId = "") => {
        setLoading(true);
        const members = await axiosHelperCore({
            url: `rapport/user/getOranizationMemberList`,
            method: "POST",
            JSONData: { organizationId: organizationId ? organizationId : loggedInMemberDetails.organizationId }
        });
        let memberList = members?.data?.data?.map(m => ({ value: m.userId, label: m.firstName + " " + m.lastName + " <" + m.email + ">" }));
        memberList.unshift({value:"-1", label:"Select Member"})
        setMemberData(memberList);
        setLoading(false);
    }

    const getAccountDetails = async(orgId) => {

        setLoading(true);

        try {
            
            const response = await axiosHelperCore({
                url: `rapport/user/getAccountDetails`,
                method: "POST",
                JSONData: { organizationId: orgId, userId: loggedInMemberDetails?.userId }
            });

            setCustomInsights(response?.data?.accountDetails?.customInsights);
            fetchUseCaseList(loggedInMemberDetails?.organizationId);
            getMemberList(loggedInMemberDetails?.organizationId);

        } catch (error) {
            console.error("Error fetching account details", error);
        }

        setLoading(false);
    };

    useEffect(() => {
        if(loggedInMemberDetails?.organizationId){
            getAccountDetails(loggedInMemberDetails?.organizationId);
        }
    }, []);

    useEffect(() => {
        if (!open) {
            setIsTrackingKeywordsChanged(false);
            setIsFactSignalKeywordsChanged(false);
            setIsHiringSignalKeywordsChanged(false);
        }
    }, [open]);

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <EvaLibraryIcon name="loadingIcon" />
                </Backdrop>
            )}
            <SnackBar
                iconName={"info"}
                message={snackbarMessageOld}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />

            <Flex justifyContent={"flex-end"} gap="40px">
                <Box display={"flex"}>
                    <Button
                        variant="secondary" 
                        fontSize={"14px"} 
                        padding={"10px 10px"}
                        onClick={() => handleOpen(null)}
                        top={20}
                    >
                        Add Use Case
                    </Button>
                    </Box>
            </Flex>  

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth style={{"padding" : "5px"}}>
                <DialogTitle>{editMode ? 'Edit Use Case' : 'Add New Use Case'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Use Case Name"
                        value={useCaseName}
                        onChange={(e) => setUseCaseName(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                        error={useCaseNameError}
                        helperText={useCaseNameError ? "Use Case name is required." : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        label="Pitch"
                        value={pitch}
                        onChange={(e) => setPitch(e.target.value)}
                        fullWidth
                        required
                        multiline
                        rows={3}
                        margin="normal"
                        error={pitchError}
                        helperText={pitchError ? "Pitch is required." : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        
                    />

                    <TextField
                        label="More Context"
                        value={persona}
                        onChange={(e) => setPersona(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <Box padding={"20px 0 0 0"}>
                        <Flex fontSize={"16px"} fontWeight={"600"}>Track News Signals</Flex>
                        <Flex fontSize={"10px"} fontWeight={"400"} paddingTop={"4px"}>Keywords here should mention signals that are not time sensitive. For example, tracking a company mentioning employee benefits on their career page, or the company uses certain technology and talks about it on their blog or a partner's website.</Flex>
                    </Box>
                    <Tooltip title="Add News Keywords and then press enter" placement="top" arrow>    
                        <TextField
                            // label="News Signals"
                            value={trackingInput}
                            onChange={(e) => setTrackingInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleTrackingKeywords()}
                            fullWidth
                            multiline
                            margin="normal"
                            sx={{
                                "& .MuiInputBase-root" : {
                                    display : "block"
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                  <Box sx={{ display: 'flex', flexWrap : "wrap", gap: '5px', marginRight: '8px', marginBottom : "5px" }}>
                                    {trackingKeywords.map((trackingInput, index) => (
                                      <Chip
                                        key={index}
                                        label={trackingInput}
                                        onDelete={() => handleDeleteTrackingKeywords(trackingInput)}
                                        style={{
                                          border: '1px solid rgb(0, 148, 227)',
                                          color: 'rgb(0, 148, 227)',
                                          backgroundColor : "white",
                                          fontSize : "12px"
                                        }}
                                        sx={{
                                            '& .MuiChip-deleteIcon': {
                                              display: 'none',
                                            },
                                            '&:hover .MuiChip-deleteIcon': {
                                              display: 'inline',
                                              color: 'white',
                                            },
                                            '&:hover': {
                                              backgroundColor: 'rgb(0, 148, 227) !important',
                                              color: 'white !important',
                                              borderColor: 'transparent',
                                            }
                                        }}
                                      />
                                    ))}
                                  </Box>
                                )
                            }}
                        />
                    </Tooltip>
                    <Box fontSize="10px" marginTop="5px" color="#9f9f9f" display={"flex"} justifyContent={"right"}>
                        (Up to {trackingLimit} Keywords allowed. {trackingKeywords.length}/{trackingLimit} saved.)
                    </Box>

                    <Box padding={"20px 0 0 0"}>
                        <Flex fontSize={"16px"} fontWeight={"600"}>Track Fact Signals</Flex>
                        <Flex fontSize={"10px"} fontWeight={"400"} paddingTop={"4px"}>Keywords here should mention signals that are not time sensitive. For example, tracking a company mentioning employee benefits on their career page, or the company uses certain technology and talks about it on their blog or a partner's website.</Flex>
                    </Box>
                    <Tooltip title="Add Fact Keywords and then press enter" placement="top" arrow>    
                        <TextField
                            // label="Fact Signals"
                            value={factInput}
                            onChange={(e) => setFactInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleFactKeywords()}
                            fullWidth
                            multiline
                            margin="normal"
                            sx={{
                                "& .MuiInputBase-root" : {
                                    display : "block"
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                  <Box sx={{ display: 'flex', flexWrap : "wrap", gap: '5px', marginRight: '8px', marginBottom : "5px"  }}>
                                    {factSignalKeywords.map((factInput, index) => (
                                      <Chip
                                        key={index}
                                        label={factInput}
                                        onDelete={() => handleDeleteFactKeywords(factInput)}
                                        style={{
                                          border: '1px solid rgb(0, 148, 227)',
                                          color: 'rgb(0, 148, 227)',
                                          backgroundColor : "white",
                                          fontSize : "12px"
                                        }}
                                        sx={{
                                            '& .MuiChip-deleteIcon': {
                                              display: 'none',
                                            },
                                            '&:hover .MuiChip-deleteIcon': {
                                              display: 'inline',
                                              color: 'white',
                                            },
                                            '&:hover': {
                                              backgroundColor: 'rgb(0, 148, 227) !important',
                                              color: 'white !important',
                                              borderColor: 'transparent',
                                            }
                                        }}
                                      />
                                    ))}
                                  </Box>
                                )
                            }}
                        />
                    </Tooltip>
                    <Box fontSize="10px" marginTop="5px" color="#9f9f9f" display={"flex"} justifyContent={"right"}>
                        (Up to {factLimit} Fact  allowed. {factSignalKeywords.length}/{factLimit} saved.)
                    </Box>

                    <Box padding={"20px 0 0 0"}>
                        <Flex fontSize={"16px"} fontWeight={"600"}>Track Hiring Signals</Flex>
                        <Flex fontSize={"10px"} fontWeight={"400"} paddingTop={"4px"}>Track open jobs by adding keywords that must be mentioned in the job description.</Flex>
                    </Box>
                    <Tooltip title="Add Hiring Keywords and then press enter" placement="top" arrow>    
                        <TextField
                            // label="Hiring Signals"
                            value={hiringInput}
                            onChange={(e) => setHiringInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleHiringKeywords()}
                            fullWidth
                            multiline
                            margin="normal"
                            sx={{
                                "& .MuiInputBase-root" : {
                                    display : "block"
                                }
                            }}
                            InputProps={{
                                startAdornment: (
                                  <Box sx={{ display: 'flex', flexWrap : "wrap", gap: '5px', marginRight: '8px', marginBottom : "5px" }}>
                                    {hiringSignalKeywords.map((hiringInput, index) => (
                                      <Chip
                                        key={index}
                                        label={hiringInput}
                                        onDelete={() => handleDeleteHiringKeywords(hiringInput)}
                                        style={{
                                          border: '1px solid rgb(0, 148, 227)',
                                          color: 'rgb(0, 148, 227)',
                                          backgroundColor : "white",
                                          fontSize : "12px"
                                        }}
                                        sx={{
                                            '& .MuiChip-deleteIcon': {
                                              display: 'none',
                                            },
                                            '&:hover .MuiChip-deleteIcon': {
                                              display: 'inline',
                                              color: 'white',
                                            },
                                            '&:hover': {
                                              backgroundColor: 'rgb(0, 148, 227) !important',
                                              color: 'white !important',
                                              borderColor: 'transparent',
                                            }
                                        }}
                                      />
                                    ))}
                                  </Box>
                                )
                            }}
                        />
                    </Tooltip>
                    <Box fontSize="10px" marginTop="5px" color="#9f9f9f" display={"flex"} justifyContent={"right"}>
                        (Up to {hiringLimit} Job allowed. {hiringSignalKeywords.length}/{hiringLimit} saved.)
                    </Box>
                    {customInsights.includes('useCase_2') &&
                    <>
                        <label htmlFor="select-member" style={{ margin: '15px 0 5px 0', display: 'block', fontWeight : "300", fontSize : "14px" }}>
                            Select Member *
                        </label>   
                        <Select
                            isMulti
                            id='select-member'
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={memberData}
                            name="menu"
                            menuPlacement='top'
                            placeholder="Select Member"
                            value={memberData.filter((option) =>
                                userList.includes(option.value)
                            )}
                            closeMenuOnSelect={false}
                            onChange={(selectedOptions) => {
                                handleSelectedMemberChange(selectedOptions);
                                setUserListFieldError(selectedOptions.length === 0);
                            }}
                            styles={{
                                control: (provided: Record<string, unknown>, state: any) => ({
                                    ...provided,
                                    padding: '8px 0',
                                    margin : "15px 0",
                                    fontWeight : "300", 
                                    fontSize : "14px",
                                }),
                            }}
                        />
                        {userListFieldError && (
                            <div style={{marginTop : "3px", color : "red", fontSize : "12px"}}>
                                Please select at least one member.
                            </div>
                        )}
                    </>}
                    {customInsights.includes('useCase_1') && <TextField
                        label="CRM Mapping Value"
                        value={crmMappingField}
                        onChange={(e) => setCrmMappingField(e.target.value)}
                        required
                        fullWidth
                        margin="normal"
                        error={crmMappingFieldError}
                        helperText={crmMappingFieldError ? "CRM Mapping Field is required." : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />}

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        color="red"
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} variant="secondary" fontSize={"14px"} padding={"10px 10px"}>Cancel</Button>
                    <Button onClick={handleSubmit} variant="primary" fontSize={"14px"} padding={"10px 10px"}>{editMode ? 'Save' : 'Submit'}</Button>
                </DialogActions>
            </Dialog>

            <Box mt={10} zIndex={9}>
                {useCases?.length > 0 &&<div className="flex flex-col">
                    <ManageUseCaseHeader />
                    {useCases.map((useCase, index) => (
                        <Flex
                            padding={"15px"}
                            fontSize="12px"
                            fontWeight={500}
                            position="sticky"
                            zIndex={0}
                            backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            key={useCase?.useCaseId}
                        >
                            <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                                {index + 1}
                            </Flex>
            
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="20%">
                                {useCase?.useCaseName}
                            </Flex>
                            
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="25%" >
                                {useCase?.updatedBy}
                            </Flex>
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="25%" >
                                {useCase?.updatedOn}
                            </Flex>
                            {/* <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="15%" >
                                {useCase?.crmMappingField}
                            </Flex> */}
                            {/* <Flex style={{ "wordBreak": "break-word" }} justifyItems={"center"} justifyContent={"center"} padding={"0 5px"} gap={"10px"} width="20%" >
                                    <span>    
                                        <ToggleButton
                                            value={useCase?.activated}
                                            name='isActive'
                                            variant="tertiary"
                                            onChange={() => handleToggle(useCase.useCaseId, !useCase.activated)}
                                        />
                                    </span>
                            
                            </Flex> */}
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} gap={"10px"} width="25%">
                                <Tooltip title={`Edit ${useCase?.useCaseName} Use Case`} placement="top" arrow>
                                    <span>
                                        <EditButton onClick={() => handleOpen(useCase)}/>
                                    </span>
                                </Tooltip>
                                <Tooltip title={`Delete ${useCase?.useCaseName} Use Case`} placement="top" arrow>
                                    <span>
                                        <DeleteButton onClick={() => handleOpenPopup(useCase)}/>
                                    </span>    
                                </Tooltip>
                            </Flex>
                        </Flex>
                    ))}
                </div>}
                {useCases?.length === 0 && <NoRecordFound />}
                <DeleteConfirmationPopup
                    personaName={selectedUseCase?.useCaseName}
                    onDelete={handleDeleteUseCase}
                    isOpen={isPopupOpen}
                    onClose={handleClosePopup}
                />
            </Box>
        </>
    );
}

export default ManageUseCaseContent;
