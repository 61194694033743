import Button from 'components/Elements/Button';
import { useEffect, useState } from "react";
import Select from 'react-select';
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Backdrop } from "@mui/material";

function HubspotForm({ isDualMode,mode, isFieldsAdded, setIsFieldsAdded, setShowForm, setConnectionText }) {

    const [showMessage, setShowMessage] = useState(false);
    const [listData, setListData] = useState([]);
    const [fields, setFields] = useState([]);
    const [accountFields, setAccountFields] = useState([]);
    const [hubspotData, setHubspotData] = useState({ listId: "", firstName: "", lastName: "", email: "", jobTitle: "", linkedinUrl: "", callPrep: "", prospectScore:"", accountScore: "", accountSummary: "" });
    const [loading, setLoading] = useState(false);
    const [IsError, setIsError] = useState(false);

    const customStyles = {
        menuPortal: (base) => ({ ...base, zIndex: 999 }),
        menu: (base) => ({
            ...base,
            maxHeight: 400, 
        }),
        menuList: (base) => ({
            ...base,
            maxHeight: 400, 
            overflowY: 'auto', 
        }),
    };

    useEffect(() => {
        getList();
        getData();
        setShowForm(true);
    }, [])

    const cancel = () => {
        setIsFieldsAdded(true);
        setShowForm(false);
    }

    const validateFields = () => {
        const fields = [
            hubspotData?.firstName,
            hubspotData?.lastName,
            hubspotData?.email,
            hubspotData?.jobTitle,
            hubspotData?.linkedinUrl,
            hubspotData?.callPrep,
            hubspotData?.prospectScore,
            hubspotData?.accountScore,
            hubspotData?.accountSummary
        ];

        console.log("fieldsfields",fields);

        const normalizedFields = fields.map(field =>
            (field !== undefined && field !== null && field.trim() !== '') ? field.trim().toLowerCase() : field
        );

        const filteredFields = normalizedFields.filter(field => field !== undefined && field !== null && field !== '');

        const uniqueFields = new Set(filteredFields);

        if (uniqueFields.size !== filteredFields.length) {
            return false;
        }

        return true;
    }


    const getList = async () => {
        setLoading(true);
        const [result, result2, result3]: any[] = await Promise.all([
            axiosHelperCore({
                url: `hubspot/fetchList`,
                method: "POST",
            }),
            axiosHelperCore({
                url: `hubspot/fetchFieldNames`,
                method: "POST",
            }),
            axiosHelperCore({
                url: `hubspot/fetchAccountFieldNames`,
                method: "POST",
            })
        ]);

        setListData(result.data.lists);
        setFields(result2.data.fields);
        setAccountFields(result3.data.fields);
        setLoading(false);
    }

    const getData = async () => {

        const result: any = await axiosHelperCore({
            url: `hubspot/getData`,
            method: "POST",
        });
        if (result?.data?.hubspotData) {
            setHubspotData(result?.data?.hubspotData);
            if (result?.data?.hubspotData && Object.keys(result?.data?.hubspotData).length > 0) {
                setConnectionText("connected");
            }
        }
        else {
            setConnectionText("Connection Pending");
            setShowForm(true)
        }



    }

    const saveData = async () => {

        // if (!validateFields()) {
        //     setIsError(true);
        //     const div = document.getElementById('scrollableDiv');
        //     div.scrollTop = 0;
        //     return;
        // }

        setIsFieldsAdded(true);
        setShowForm(false);
        const result: any = await axiosHelperCore({
            url: `hubspot/saveData`,
            method: "POST",
            JSONData: { hubspotData: hubspotData }
        });
        setConnectionText("connected");
        setShowForm(false);
    }

    if (isDualMode && !mode)
        return (<>  </>)


    return (
        <>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                <Icon name="loadingIcon" />
            </Backdrop>}

            <div id='scrollableDiv' className='flex flex-col gap-[8px]' style={{ width: "90%", padding: "10px", margin: "0 auto", maxHeight: "40vh", scrollbarWidth: "none", overflowY: "auto" }}>
                {(!mode || mode === "all") && (
                    <div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div className='mb-1'>Prospect Fields</div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select List<sup className='text-red-500'>*</sup></div>
                            <Select
                                styles={customStyles}
                                menuPortalTarget={document.body}
                                className="text-sm  "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field1"
                                value={listData?.filter(f => f.listId == hubspotData.listId).map(f => ({ label: f.name, value: f.listId }))}
                                options={listData?.map(f => ({ label: f.name, value: f.listId }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        listId: selectedField.value
                                    }));

                                }} />
                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select First Name</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_listid"
                                value={fields?.filter(f => f.name == hubspotData.firstName).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        firstName: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Last Name</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_lastname"
                                value={fields?.filter(f => f.name == hubspotData.lastName).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        lastName: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Email<sup className='text-red-500'>*</sup></div>

                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_email"
                                value={fields?.filter(f => f.name == hubspotData.email).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        email: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Job Title</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_jobtitle"
                                value={fields?.filter(f => f.name == hubspotData.jobTitle).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        jobTitle: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select LinkedIn Url<sup className='text-red-500'>*</sup></div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_linkedin"
                                value={fields?.filter(f => f.name == hubspotData.linkedinUrl).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        linkedinUrl: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Call Prep</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_callprep"
                                value={fields?.filter(f => f.name == hubspotData.callPrep).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        callPrep: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Prospect Account Score</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_prospect_score"
                                value={fields?.filter(f => f.name == hubspotData.prospectScore).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        prospectScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mb-1 mt-4'>Account Fields</div>
                    
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Score</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_score"
                                value={accountFields?.filter(f => f.name == hubspotData.accountScore).map(f => ({ label: f.label, value: f.name }))}
                                options={accountFields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        accountScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Summary</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_summary"
                                value={accountFields?.filter(f => f.name == hubspotData.accountSummary).map(f => ({ label: f.label, value: f.name }))}
                                options={accountFields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        accountSummary: selectedField.value
                                    }));
                                }} />

                        </div>
                        {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                            Settings successfully saved!
                        </div>}
                        <div className='mt-5 text-center flex flex-wrap space-x-2 justify-center'>
                            <Button variant={"salesforce"} text={isFieldsAdded ? "Save" : "Save"} onClick={saveData} disabled={!(hubspotData.listId && hubspotData.email && hubspotData.linkedinUrl)} />
                            <Button variant={"secondary"} text={"Cancel"} onClick={cancel} />

                        </div>
                    </div>)}


                {mode === "read" && (
                    <div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div className='mb-1'>Prospect Fields</div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select List<sup className='text-red-500'>*</sup></div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm  "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field1"
                                value={listData?.filter(f => f.listId == hubspotData.listId).map(f => ({ label: f.name, value: f.listId }))}
                                options={listData?.map(f => ({ label: f.name, value: f.listId }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        listId: selectedField.value
                                    }));

                                }} />
                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select First Name</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_listid"
                                value={fields?.filter(f => f.name == hubspotData.firstName).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        firstName: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Last Name</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_lastname"
                                value={fields?.filter(f => f.name == hubspotData.lastName).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        lastName: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Email<sup className='text-red-500'>*</sup></div>

                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_email"
                                value={fields?.filter(f => f.name == hubspotData.email).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        email: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Job Title</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_jobtitle"
                                value={fields?.filter(f => f.name == hubspotData.jobTitle).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        jobTitle: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select LinkedIn Url<sup className='text-red-500'>*</sup></div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_linkedin"
                                value={fields?.filter(f => f.name == hubspotData.linkedinUrl).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        linkedinUrl: selectedField.value
                                    }));
                                }} />

                        </div>

                        {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                            Settings successfully saved!
                        </div>}
                        <div className='mt-5 text-center flex flex-wrap space-x-2 justify-center'>
                            <Button variant={"salesforce"} text={isFieldsAdded ? "Save" : "Save"} onClick={saveData} disabled={!(hubspotData.listId && hubspotData.email && hubspotData.linkedinUrl)} />
                            <Button variant={"secondary"} text={"Cancel"} onClick={cancel} />

                        </div>
                    </div>)}


                {mode === "write" && (
                    <div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div className='mb-1'>Prospect Fields</div>
                        <div>
                            <div className='text-sm text-[#57585C] mt-3 mb-1'>Select Call Prep</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_callprep"
                                value={fields?.filter(f => f.name == hubspotData.callPrep).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        callPrep: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Prospect Account Score</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_prospect_score"
                                value={fields?.filter(f => f.name == hubspotData.prospectScore).map(f => ({ label: f.label, value: f.name }))}
                                options={fields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        prospectScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mb-1 mt-4'>Account Fields</div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Score</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_score"
                                value={accountFields?.filter(f => f.name == hubspotData.accountScore).map(f => ({ label: f.label, value: f.name }))}
                                options={accountFields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        accountScore: selectedField.value
                                    }));
                                }} />

                        </div>
                        <div className='mt-3'>
                            <div className='text-sm text-[#57585C] mt-1 mb-1'>Select Account Summary</div>
                            <Select
                             styles={customStyles}
                             menuPortalTarget={document.body}
                                className="text-sm "
                                menuPlacement='auto'
                                placeholder="Select"
                                name="field_account_summary"
                                value={accountFields?.filter(f => f.name == hubspotData.accountSummary).map(f => ({ label: f.label, value: f.name }))}
                                options={accountFields?.map(f => ({ label: f.label, value: f.name }))}
                                onChange={(selectedField) => {
                                    setHubspotData((prevHubspotData) => ({
                                        ...prevHubspotData,
                                        accountSummary: selectedField.value
                                    }));
                                }} />

                        </div>
                        {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                            Settings successfully saved!
                        </div>}
                        <div className='mt-6 text-center flex flex-wrap space-x-2 justify-center'>
                            <Button variant={"salesforce"} text={isFieldsAdded ? "Save" : "Save"} onClick={saveData} />
                            <Button variant={"secondary"} text={"Cancel"} onClick={cancel} />

                        </div>
                    </div>)}

            </div>
        </>
    )
}

export default HubspotForm;